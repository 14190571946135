import { useEffect, useState } from "react";
import { useNavigate, NavLink } from "react-router-dom";
import { auth, api } from "services";

export default function Menu(props) {
  const [managers, setManagers] = useState([]);
  let navigate = useNavigate();

  useEffect(() => {
    api.accountManagers().then((res) => {
      setManagers(()=>res);
    });
  }, []);

  const manager1 = managers[0];
  const logout = () => {
    auth
      .logout()
      .then((result) => {
        location.replace("/login");
      })
      .catch((error) => {
        alert(error.message);
      });
  };

  const gotopage = (page) => {
    navigate(page);
  };

    return (
      <div
        className="offcanvas offcanvas-end text-danger"
        tabIndex="-1"
        id="menuDrawer"
      >
        <div className="offcanvas-header bg-app text-light">
          <h1 className="offcanvas-title">Menu</h1>
          <button
            type="button"
            className="btn-close text-reset bg-light"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body">
          <ul className="list-unstyled text-dark">
            {/* {managers.length !== 0 ? <RM manager={managers[0]} /> : null }
            <hr className="text-dark" /> */}

            <li className="my-3">
              <button
                className="fs-4 btn btn-outline-danger w-100 text-start mb-1 py-3"
                onClick={() => gotopage("/profile")}
                data-bs-dismiss="offcanvas"
              >
                <i className="bi bi-person-circle pe-1"></i> Profile
              </button>
            </li>
            
            <li className="my-3">
              <button
                className="fs-4 btn btn-outline-danger w-100 text-start mb-1 py-3"
                onClick={() => gotopage("/settings")}
                data-bs-dismiss="offcanvas"
              >
                <i className="bi bi-gear pe-1"></i> Settings
              </button>
            </li>
            <li className="my-3">
              <button
                className="fs-4 btn btn-outline-danger w-100 text-start mb-1 py-3"
                onClick={() => gotopage("/help")}
                data-bs-dismiss="offcanvas"
              >
                <i className="bi bi-info-circle-fill pe-1"></i> Help & Support
              </button>
            </li>

            <hr className="text-dark" />
            <li>
              <a
                className="fs-4 btn btn-app w-100 text-start py-3"
                role="button"
                onClick={logout}
              >
                <i className="bi bi-arrow-left pe-1"></i>
                <span className="fs-3">Logout</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    );
  };