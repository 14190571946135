import React, { useEffect, useState } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { images } from 'assets/themes';
import { auth } from 'services';

function Header() {
    const location = useLocation();
    
    const [khataView, setKhataView] = useState(
        location.pathname.includes('khata/')
    )

    useEffect(() => {
        setKhataView(() => location.pathname.includes('khata/'))
    }, [location]);

    const logout = () => {
        auth.logout().then((result) => {
            location.replace('/login');
        }).catch((error) => {
            alert(error.message)
        })
    }

    const user = auth.fetchUser()

    if (window.location.pathname === '/login' ||
        window.location.pathname === '/signup' ||
        window.location.pathname === '/onboarding') {
        return null;
    }

    const HeaderBtns = () => (
        <ul className="navbar-nav ms-auto">
            <li className="nav-item dropstart mb-1">
                <div className="d-flex">
                    <a className="nav-link text-light fs-2 py-0 me-2"
                        data-bs-toggle="offcanvas"
                        href="#menuDrawer"
                        role="button">
                        <i className="bi bi-person-circle"></i>
                    </a>
                </div>

                <ul className="dropdown-menu">
                    <li className="fs-6 px-3">{user.get('name')}</li>
                    <li><hr className="dropdown-divider" /></li>
                    <li>
                        <Link className="dropdown-item fs-6" to="/profile">Profile</Link>
                    </li>
                    <li>
                        <Link className="dropdown-item fs-6" to="/settings">Settings</Link>
                    </li>
                    <li>
                        <Link className="dropdown-item fs-6" to="/help">Help & Support</Link>
                    </li>
                    <li><hr className="dropdown-divider" /></li>
                    <li>
                        <a className="dropdown-item fs-6" role="button" onClick={logout}>Logout</a>
                    </li>
                </ul>
            </li>
        </ul>
    )

    return (
        <nav className="navbar navbar-expand bg-app">
            <div className="container-fluid">
                <span className="me-auto">
                    <Link className="navbar-brand col-9" to="/" title="Home">
                        <img src={images.logo}
                            height="40"
                            className="ff-serif" />
                        <span className="text-light mx-2">Bahi Khata</span>
                    </Link>
                </span>
                <div className="collapse navbar-collapse" id="myNav">
                    <ul className="navbar-nav me-auto">
                        <li className="nav-item">
                            <NavLink className={`first-step ${khataView
                                        ? 'nav-link fs-6 text-light active'
                                        : 'nav-link fs-6 text-light'
                                }`} to="/">
                            KHATABOOK
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink
                                className="nav-link fs-6 text-light"
                                to="/cashbook">
                                CASHBOOK
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink
                                className="nav-link fs-6 text-light disabled"
                                to="/passbook">
                                PASSBOOK{' '}
                                <span className="bg-white text-dark badge">
                                    Coming Soon
                                </span>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink
                                className="nav-link fs-6 text-light"
                                to="/funding">
                                FUNDING{' '}
                            </NavLink>
                        </li>
                    </ul>

                    <HeaderBtns />
                </div>
            </div>
        </nav>
    )
}
export default Header
