import React, { useState, useEffect } from "react";
import { 
  faUserPlus
} from "@fortawesome/free-solid-svg-icons";
import { api } from "services";
import Account from "./Account";

import { stringSort, numSort } from 'utils';
import ExpandableButton from "components/ExpandableButton";
import { useOnboardingStore } from "store/onboarding-store";
import useUniversalStore from "store/universalStore";

function Index() {
  // Fetch accounts from the global state
  const accounts = useUniversalStore((state) => state.accounts);
  const goToNextStep = useOnboardingStore((store) => store.goToNextStep);

  const [filters, setFilters] = useState({
    search: '',
    hideClosed: api.getUserKhatabookFilter(),
    sort: { col: "name", desc: false }
  });

  const filteredAccounts = () => {
    if(!accounts) return [];
    
    let result = accounts;
    if(filters.search.trim()) {
      const searchTerm = filters.search.toLowerCase();
      result = result.filter((account) =>
        account.get("name").toLowerCase().includes(searchTerm) ||
        account.get("phone")?.includes(filters.search)
      );
    }

    result = filters.sort.col === "balance" 
      ? numSort(result, filters.sort.desc, filters.sort.col)
      : stringSort(result, filters.sort.desc, filters.sort.col);
  
    return filters.hideClosed
      ? result.filter(account => !account.get("cancelled")) 
      : result;
  }

  const handleSearchInput = (e) => setFilters({...filters, search: e.target.value});
  const clearSearch = () => setFilters({...filters, search: ''});

  const SearchBar = () => (
    <div className="input-group my-3 rounded-2">
      <span className="input-group-text btn border border-dark border-start-0 border-top-0 border-bottom-0" id="inputgroupicon">
        <i className="bi bi-search"></i>
      </span>
      <input
        type="text"
        autoFocus
        className="form-control py-0 col-8 accountsearch btn text-start"
        placeholder="Search accounts"
        value={filters.search}
        onChange={handleSearchInput}
      />
      {filters.search && (
        <button 
          className="btn btn-outline-secondary" 
          onClick={clearSearch}
          type="button"
        >
          <i className="bi bi-x"></i>
        </button>
      )}
    </div>
  )

  const Filters = () => (
      <div className="justify-content-around d-flex align-items-baseline border shadow">
          {/* search bar */}
          <SearchBar />

          <span className="w-25">
            <i className={`fs-4 px-4 bi  ${filters.hideClosed ? "bi-filter-circle-fill text-danger": "bi-filter"}`}
              role="button"
              onClick={() => setFilters({...filters, hideClosed: !filters.hideClosed})}></i>
            <i className="bi bi-arrow-down-up dropdown-toggle"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"></i>
            <ul className="dropdown-menu">
              <li className="dropdown-item d-flex"
                onClick={(e) => setFilters({ ...filters, sort: { col: "name", desc: !filters.sort.desc } })}>
                <span className="me-auto">Name</span>
                <span className={filters.sort.col === "name" ? "" : "invisible"}>
                  <i className={`bi ${filters.sort.desc ? "bi-sort-alpha-down" : "bi bi-sort-alpha-up"}`}></i>
                </span>
              </li>
              <li className="dropdown-item d-flex"
                onClick={(e) => setFilters({ ...filters, sort: { col: "balance", desc: !filters.sort.desc } })}>
                <span className="me-auto">Balance</span>
                <span className={filters.sort.col === "balance" ? "" : "invisible"}>
                  <i className={`bi ${filters.sort.desc ?
                    "bi-sort-numeric-up-alt" : "bi bi-sort-numeric-down"}`}></i>
                </span>
              </li>
            </ul>
          </span>
        </div>
  )

  return (
    <div className="d-flex flex-column vh-100">
      <Filters />

      <ul className="list-group list-group-flush scrollbar-design 
        flex-grow-1 overflow-auto">
          {filteredAccounts().map(account => (
            <Account key={account.id} account={account} />
          ))}
      </ul>
        
      <ExpandableButton 
        title="Add Account"
        clickForm="addAccountDrawer"
        onClick={(e) => goToNextStep()}
        icon={faUserPlus} />
    </div>
  );
}

export default Index;