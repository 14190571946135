const Loading = ({ enabled }) => {
    if(!enabled){
        return <></>;
    } 
    return (
        <div className="form-group text-start">
            <div className="form-check-label alert alert-warning text-capitalized" role="alert">
                <span className="spinner-border spinner-border-sm"></span> Loading...
            </div>
        </div>
    )
    
}

export default Loading;