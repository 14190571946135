import { create } from "zustand";
import { auth } from "services";

const useUserStore = create((set, get) => ({
    user: null,
    updateUser: async (values) => {
        try {
            const _user = auth.updateUser(values);
            set({user: _user});
        } catch(error){
            console.error("Error fetching user:", error);
        }
    },
    getUser: async () => {
        try {
            const _user = auth.fetchUser();
            console.log("fetching data");
            set({user: _user});
          } catch (error) {
            console.error("Error fetching user:", error);
          }
    }
}))

export default useUserStore;