import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isValidPhoneNumber } from 'react-phone-number-input';
import {
    BrowserRouter as Router,
    Route,
    Link,
    useNavigate,
    useLocation,
    Navigate
} from 'react-router-dom';
import { auth, sms } from 'services';
const user = auth.fetchUser();
import { images, metrics } from 'assets/themes';
import "./Login.css";

const Login = (props) => {
    const { type } = props;
    const navigate = useNavigate()
    const { t } = useTranslation();
    const [selectedCountry, setSelectedCountry] = useState(0)
    const [otpSent, setOtpSent] = useState(false)
    const [invalid, setInValid] = useState(true)
    const [otpVerified, setOtpVerified] = useState(false)
    const [resendWaitTime, setResendWaitTime] = useState(30)
    const [loading, setLoading] = useState(false)
    const [values, setValues] = useState({
        phone: '',
        otp: '',
        errormsg: '',
        successmsg: ''
    })
    const countries = [
        {
            name: 'india',
            country_code: 'IN',
            dialing_code: '91',
            flag_url: images.India
        },
        {
            name: 'nepal',
            country_code: 'NP',
            dialing_code: '977',
            flag_url: images.Nepal
        },
        {
            name: 'bangladesh',
            country_code: 'BD',
            dialing_code: '880',
            flag_url: images.Bangladesh
        },
        {
            name: 'pakistan',
            country_code: 'PK',
            dialing_code: '92',
            flag_url: images.Pakistan
        }
    ]

    const failure = (message) => {
        setValues({
            ...values,
            successmsg: '',
            errormsg: message
        })

        setTimeout(() => {
            setValues({
                ...values,
                errormsg: ''
            })
        }, 5000)
    }

    const success = (message) => {
        setValues({
            ...values,
            errormsg: '',
            successmsg: message
        })

        setTimeout(() => {
            setValues({
                ...values,
                successmsg: ''
            })
        }, 5000)
    }

    useEffect(() => {
        if (resendWaitTime && otpSent && !otpVerified)
            setTimeout(() => {
                setResendWaitTime(() => resendWaitTime - 1)
                // console.log(resendWaitTime);
            }, 1000)
    }, [resendWaitTime])

    useEffect(() => {
        // console.log("+91" + phone + ":", isPossiblePhoneNumber("+91" + phone), isValidPhoneNumber("+91" + phone));
        let temp =
            isValidPhoneNumber(
                '+' + countries[selectedCountry].dialing_code + values.phone
            ) || values.phone == ''
        setInValid(!validatePhone())
    }, [values.phone])

    const validatePhone = () => {
        const temp1 =
            isValidPhoneNumber(
                '+' + countries[selectedCountry].dialing_code + values.phone
            ) || values.phone == ''
        let temp2
        switch (selectedCountry) {
            case 0:
                temp2 = values.phone.length == 10
                break
            case 1:
                temp2 = values.phone.length == 8
                break
            case 2:
                temp2 = values.phone.length == 10 || values.phone.length == 9
                break
            case 3:
                temp2 = values.phone.length == 10
                break

            default:
                temp2 = false
                break
        }
        // console.log(temp1, temp2);
        return temp1 && temp2
    }

    const handlePhoneChange = (e) => {
        const target = e.target
        setValues((values) => ({
            ...values,
            phone: target.value,
            errormsg: ''
        }))
    }

    const handleOtpChange = (e) => {
        const target = e.target
        setValues((values) => ({
            ...values,
            otp: target.value,
            errormsg: ''
        }))
    }

    const sendOTP = (e) => {
        e.preventDefault()
        // console.log("+" + countries[selectedCountry].dialing_code + values.phone, countries[selectedCountry].dialing_code + values.phone)
        const temp = isValidPhoneNumber(
            '+' + countries[selectedCountry].dialing_code + values.phone
        )
        if (temp) {
            setOtpVerified(() => false)
            sms.sendOTP(countries[selectedCountry].dialing_code + values.phone)
                .then((result) => {
                    // console.log(result);
                    if (result.type == 'success') {
                        // otp sent
                        setResendWaitTime(29)
                        setOtpSent(() => true)
                        success(
                            'OTP sent to ' + values.phone + ' successfully !'
                        )
                    } else {
                        // error
                        failure(result.message)
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
        } else {
            failure('Invalid Phone no.')
        }
    }

    const resendviaSms = (e) => {
        e.preventDefault()
        // alert("resend")
        sms.resendOTP(
            countries[selectedCountry].dialing_code + values.phone,
            'text'
        ).then((result) => {
            success('OTP resent successfully!')
            setResendWaitTime(29)
        })
    }

    const resendviaCall = (e) => {
        e.preventDefault()
        // alert("resend")
        sms.resendOTP(
            countries[selectedCountry].dialing_code + values.phone,
            'call'
        ).then((result) => {
            success('OTP resent via call successfully!')
            setResendWaitTime(29)
        })
    }

    const verifyFunction = (e) => {
        e.preventDefault()
        sms.verifyOTP(countries[selectedCountry].dialing_code + values.phone,values.otp)
            .then((result) => {
                if (!result.type) {
                    setLoading(() => true)
                    success('OTP verified successfully!')
                    if (
                        !localStorage.getItem(
                            `Parse/${process.env.REACT_APP_APPID}/currentUser`
                        )
                    ) {
                        localStorage.setItem(
                            `Parse/${process.env.REACT_APP_APPID}/currentUser`,
                            JSON.stringify(result)
                        )
                    }
                    if (type == 'login') {
                        window.location = '/'
                    } else {
                        window.location = '/onboarding'
                    }
                } else if (result.message == 'OTP not match') {
                    failure('OTP is incorrect! Please enter correct otp.')
                } else {
                    failure('Something went wrong!')
                }
            })
            .catch((error) => {
                console.log(error)
                failure(error.message)
            })
    }

    if (user && type == 'login') {
        return <Navigate to="/" />
    } else if (user && type == 'signup') {
        return <Navigate to="/onboarding" />
    }

    return (
        <div className="auth-container">
            <div className="auth-wrapper">
                <div className="auth-card">
                    <div className="auth-header">
                        <img src={images.logo} alt="Logo" className="auth-logo" />
                        <div className="auth-title">
                            <h1>{t(type === 'signup' ? 'Signup to' : 'Login to')}</h1>
                            <h1 className="text-danger">{t('Bahi Khata')}</h1>
                        </div>
                    </div>

                    <form onSubmit={otpSent ? verifyFunction : sendOTP} className="auth-form">
                        <div className="phone-input-group">
                            <div className="country-dropdown">
                                <button
                                    className="btn dropdown-toggle"
                                    type="button"
                                    data-bs-toggle="dropdown"
                                    title={countries[selectedCountry].name.toUpperCase()}
                                >
                                    <img
                                        src={countries[selectedCountry].flag_url}
                                        alt={countries[selectedCountry].name}
                                        className="country-flag"
                                    />
                                    <span className="country-code">
                                        +{countries[selectedCountry].dialing_code}
                                    </span>
                                </button>
                                <ul className="dropdown-menu">
                                    {countries.map((country, index) => (
                                        <li key={country.country_code}>
                                            <button
                                                className="dropdown-item"
                                                onClick={() => setSelectedCountry(index)}
                                                type="button"
                                            >
                                                <img src={country.flag_url} alt={country.name} className="country-flag" />
                                                <span>+{country.dialing_code}</span>
                                            </button>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <input
                                type="number"
                                className={`form-control-borderless ${invalid ? 'is-invalid' : values.phone ? 'is-valid' : ''}`}
                                placeholder="Phone number"
                                value={values.phone}
                                onChange={handlePhoneChange}
                                required
                            />
                        </div>

                        {otpSent && (
                            <div className="otp-section">
                                <input
                                    type="number"
                                    className="form-control border"
                                    placeholder="Enter OTP"
                                    value={values.otp}
                                    onChange={handleOtpChange}
                                    required
                                />
                                
                                <div className="resend-section">
                                    {resendWaitTime > 0 ? (
                                        <span className="timer">Resend OTP in {resendWaitTime}s</span>
                                    ) : (
                                        <div className="resend-buttons">
                                            <button onClick={resendviaSms} className="btn btn-outline-danger">
                                                Resend via SMS
                                            </button>
                                            <button onClick={resendviaCall} className="btn btn-outline-danger">
                                                Get OTP on Call
                                            </button>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}

                        {values.errormsg && (
                            <div className="alert alert-danger" role="alert">
                                <i className="bi bi-exclamation-circle-fill me-2"></i>
                                {values.errormsg}
                            </div>
                        )}

                        {values.successmsg && (
                            <div className="alert alert-success" role="alert">
                                <i className="bi bi-check-circle-fill me-2"></i>
                                {values.successmsg}
                            </div>
                        )}

                        <button
                            type="submit"
                            disabled={invalid || loading}
                            className="submit-button"
                        >
                            {loading ? (
                                <span className="spinner-border spinner-border-sm me-2"></span>
                            ) : null}
                            {loading ? 'Please wait...' : otpSent ? 'Verify OTP' : 'Send OTP'}
                        </button>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Login
