import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { api } from "services";
import "react-phone-number-input/style.css";
import { isValidPhoneNumber } from "react-phone-number-input";
import { debounce, hideOffCanvas } from "utils";
import { Loading, Status } from "components";
import useUniversalStore from "store/universalStore";
import { useNavigate } from "react-router-dom";
import Creatable from "react-select/creatable";
import { RiCloseCircleFill } from "react-icons/ri";

const NewAccount = () => {
  const { updateRefresh } = useUniversalStore();
  const navigate = useNavigate();
  const selectRef = useRef();
const profileRef = useRef();
  const [invalidPhone, setInvalidPhone] = useState(true);
  const [allContacts, setAllContacts] = useState([]);
  const [options, setOptions] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [values, setValues] = useState({
    name: "",
    phone: "",
    address: "",
    markup: "",
    profilePhoto: null,
    loading: false,
    status: { type: null, message: "" },
  });
  const [profilePhotoPreview, setProfilePhotoPreview] = useState(null);

  const fetchContacts = useCallback(async () => {
    setIsFetching(true);
    try {
      const res = await api.getContacts();
      if (res && res[0]) {
        const contacts = res[0].get("phonebook") || [];
        setAllContacts(contacts);
      }
    } catch (error) {
      console.error("Failed to fetch contacts", error);
    } finally {
      setIsFetching(false);
    }
  }, []);

  useEffect(() => {
    fetchContacts();
  }, [fetchContacts]);

  const contactOptions = useMemo(() => {
    return allContacts.map((contact) => ({
      value: contact.number.replace(/\s+/g, ""),
      label: contact.name,
    }));
  }, [allContacts]);

  useEffect(() => {
    setOptions(contactOptions);
  }, [contactOptions]);


  useEffect(() => {
    const offCanvas = document.getElementById("addAccountDrawer");
    if (!offCanvas) return;

    const handleHidden = () => {
      setValues({
        name: "",
        phone: "",
        address: "",
        markup: "",
        profilePhoto: null,
        loading: false,
        status: { type: null, message: "" },
      });
    };

    offCanvas.addEventListener("hidden.bs.offcanvas", handleHidden);

    return () => {
      offCanvas.removeEventListener("hidden.bs.offcanvas", handleHidden);
    };
  }, []);

  useEffect(() => {
    setInvalidPhone(
      !(
        validatePhoneNumber(values.phone) ||
        values.phone === ""
      )
    );
    console.log(validatePhoneNumber(values.phone));
    
  }, [values.phone]);

  useEffect(() => {
    if (values.profilePhoto) {
      const objectUrl = URL.createObjectURL(values.profilePhoto);
      setProfilePhotoPreview(objectUrl);

      return () => URL.revokeObjectURL(objectUrl);
    } else {
      setProfilePhotoPreview(null);
    }
  }, [values.profilePhoto]);

  function validatePhoneNumber(phoneNumber) {
    if(removeCountryCode(phoneNumber).length  > 10 || removeCountryCode(phoneNumber).length < 10) return false;
    return true;
  }
  
  function removeCountryCode(phoneNumber) {
    const code = phoneNumber.replace(/\s+/g, "").replace("+91", "");
    //console.log(code);
    return code;
  }

  const addAccount = async (e) => {
    e.preventDefault();
    setValues((prev) => ({ ...prev, loading: true }));

    try {
      const account = await api.getAccountByName(values.name);
      if (account) {
        setValues((prev) => ({
          ...prev,
          loading: false,
          status: {
            type: "error",
            message: "Account with this name already exists.",
          },
        }));
      } else {
        const result = await api.createAccount(
          values.name,
          removeCountryCode(values.phone),
          values.address,
          values.markup,
          values.profilePhoto
        );
        console.log(result);

        setValues({
          name: "",
          phone: "",
          address: "",
          markup: "",
          profilePhoto: null,
          loading: false,
          status: { type: "success", message: "Account created successfully!" },
        });

        setTimeout(() => {
          hideOffCanvas("addAccountDrawer");
          updateRefresh("account");
          navigate("/khata/" + result.id);
        }, 500);
      }
    } catch (error) {
      setValues((prev) => ({
        ...prev,
        loading: false,
        status: { type: "error", message: error.message || "Failed to create account." },
      }));
    }
  };

  const updateField = useCallback((field, value) => {
    setValues((prev) => ({ ...prev, [field]: value }));
  }, []);

  const handleProfilePhotoChange = useCallback((e) => {
    
    if (e.target.files && e.target.files[0]) {
      updateField("profilePhoto", e.target.files[0]);
    }
  }, [updateField]);

  const accountChangeHandler = useCallback((selectedOption) => {
    if (
      !selectedOption ||
      !selectedOption.value ||
      !selectedOption.label
    ) {
      setValues((prev) => ({
        ...prev,
        name: "",
        phone: "",
      }));
      return;
    }
    setValues((prev) => ({
      ...prev,
      name: selectedOption.label,
      phone: selectedOption.value,
    }));
  }, []);

  const selectContacts = useCallback(() => (
    <Creatable
      ref={selectRef}
      options={options}
      value={
        values.name
          ? { label: values.name, value: values.phone }
          : null
      }
      classNamePrefix="rs"
      isClearable
      placeholder="Party Name"
      onChange={accountChangeHandler}
      styles={{
        option: (baseStyles, state) => ({
          ...baseStyles,
          color: state.isSelected ? "white" : "black",
          fontSize: 18,
          backgroundColor: state.isFocused
            ? "#FFCCCB"
            : state.isSelected
            ? "#dc3545"
            : "white",
        }),
        menuList: (baseStyles) => ({
          ...baseStyles,
          backgroundColor: "white",
        }),
        clearIndicator: (baseStyles) => ({
          ...baseStyles,
          color: "red",
        }),
      }}
    />
  ), [options, values.name, values.phone, accountChangeHandler]);

  return (
    <div
      className="offcanvas offcanvas-start text-danger"
      tabIndex="-1"
      id="addAccountDrawer"
    >
      <div className="offcanvas-header bg-app text-light">
        <h3 className="offcanvas-title">Add Account</h3>
        <button
          type="button"
          className="btn-close text-reset bg-light"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
      </div>
      <div className="offcanvas-body container-fluid">
        <form onSubmit={addAccount} id="newaccountform">
          <div className="mb-3">
            {isFetching ? "Loading contacts..." : selectContacts()}
          </div>
          <div className="mb-3">
            <input
              type="tel"
              className={`form-control ${
                invalidPhone ? "is-invalid" : values.phone ? "is-valid" : ""
              }`}
              placeholder="Phone no. (Optional)"
              value={values.phone}
              onChange={(e) => updateField("phone", e.target.value)}
            />
            {invalidPhone && (
              <div className="invalid-feedback">
                Please enter a valid phone number.
              </div>
            )}
          </div>
          <div className="mb-3">
            <input
              type="text"
              className={`form-control ${values.address ? "is-valid" : ""}`}
              placeholder="Address (Optional)"
              value={values.address}
              onChange={(e) => updateField("address", e.target.value)}
              
            />
          </div>
          <div className="mb-3">
            <textarea
              className={`form-control ${values.markup ? "is-valid" : ""}`}
              placeholder="Remarks (Optional)"
              value={values.markup}
              onChange={(e) => updateField("markup", e.target.value)}
              rows="3"
            />
          </div>
          <div className="mb-3">
            <label htmlFor="profilePhoto" className="form-label">
              Profile Photo
            </label>
            <input
            ref={profileRef}
              type="file"
              className="form-control"
              id="profilePhoto"
              accept="image/*"
              onChange={handleProfilePhotoChange}
            />
            {profilePhotoPreview && (
              <div className="mt-2" >
                <div className="d-flex">
                <RiCloseCircleFill className="text-white border border-danger rounded-circle border-2 fs-5 " style={{cursor: "pointer", marginBottom: "-30px", marginLeft: "5px", zIndex: "1000"}} onClick={() => {updateField("profilePhoto", null);
                profileRef.current.value = null;
                }} />

                </div>
                <img
                  src={profilePhotoPreview}
                  alt="Profile Preview"
                  className=""
                  width="100"
                />
              </div>
            )}
          </div>
          <Loading enabled={values.loading} />
          <Status type={values.status.type} message={values.status.message} />
          <div className="d-grid">
            <button
              type="submit"
              className="btn btn-outline-danger py-3"
              disabled={!values.name || invalidPhone}
            >
              <b>Add</b>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default NewAccount;
