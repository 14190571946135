import React, { useEffect, useState } from "react"
import { api } from "services";
import useUniversalStore from "store/universalStore";
import EachRtxn from "./EachRtxn";

const RecursivetxnsView = (props) => {
    const { account, getOpenKhata } = props;
    const [txns, setTxns] = useState([]);
    const [show, setShow] = useState(false);
const recursiveRefresh = useUniversalStore((state) => state.refresh.recursiveRefresh);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    useEffect(() => {

        if (window.location.href.includes("khata/")) {
            if (!account.id) {
                getOpenKhata();
            }
        }
        refreshTxns();

    }, [account,recursiveRefresh]);



    const refreshTxns = () => {
        api.fetchRecursiveTxns(account.id)
            .then((result) => {
                setTxns(result);
            })
            .catch((error) => console.log(error));
    }

    return (
        <div>
            <button
                className="btn btn-danger rounded w-100"
                onClick={handleShow}
                data-bs-toggle="offcanvas"
                data-bs-target="#rcTxnsView"
                aria-controls="rcTxnsView"
                title="Add recursive transaction">
                <span className="bi bi-arrow-repeat" style={{ fontSize: "2rem" }}></span>
                <span style={{ fontSize: "1.8rem" }}></span>
            </button>

            <div className="offcanvas offcanvas-end text-danger" tabIndex="-1" id="rcTxnsView" aria-labelledby="">
                <div className="offcanvas-header bg-danger text-light">
                    <h3 className="offcanvas-title text-start d-flex">
                        <i className="bi bi-arrow-repeat my-auto h5 me-2" style={{ fontSize: "2rem" }}></i>
                        <span className="h5 my-auto"> Recurring Transactions</span>
                    </h3>
                    <button
                        type="button"
                        className="btn-close text-reset bg-light"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                        onClick={handleClose}
                    ></button>
                </div>
                <div className="offcanvas-body">
                    {txns.length!=0 ?
                        <>
                            <div className="row">
                                <br />
                                {txns.map((txn, index) => <EachRtxn txn={txn} key={txn.id} triggertxns={refreshTxns} />)}
                            </div>
                        </> :
                        <div className="row h-100 align-items-center justify-content-center fw-bold flex-column">
                            <div className="bi bi-database-add fs-1 text-center w-100"></div>
                            <div className="text-center w-100">Add a recurring txn!</div>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
};

export default RecursivetxnsView;
