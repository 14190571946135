import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { api } from "services";
import "react-phone-number-input/style.css";
import { isValidPhoneNumber } from "react-phone-number-input";
import { useNavigate } from "react-router-dom";
import { debounce, hideOffCanvas } from "utils";
import { Loading, Status } from "components";
import useUniversalStore from "store/universalStore";
import { RiCloseCircleFill } from "react-icons/ri";

const EditAccount = () => {
  const { updateRefresh, accountId } = useUniversalStore();
  const [invalidPhone, setInvalidPhone] = useState(true);
  const profileRef = useRef();

  const initialState = {
    name: "",
    phone: "",
    address: "",
    remark: "",
    profilePhoto: null,
    loading: false,
    status: { type: null, message: "" },
  };

  const [values, setValues] = useState(initialState);
  const navigate = useNavigate();

  const updateField = useCallback((field, value) => {
    setValues((prev) => ({ ...prev, [field]: value }));
  }, []);

  useEffect(() => {
    alert("add offcanvas");
    const offCanvas = document.getElementById("editAccountDrawer");
    offCanvas.addEventListener("shown.bs.offcanvas", () => {
      api.getAccountById(accountId).then((account) => {
        if (account) {
          console.log(account.get("profilePhoto"));

          setValues((prev) => ({
            ...prev,
            name: account.get("name"),
            phone: account.get("phone"),
            address: account.get("address"),
            markup: account.get("markup"),
            profilePhoto: account.get("profilePhoto") || null,
          }));
        }
      });
    });
    offCanvas.addEventListener("hidden.bs.offcanvas", () => {
      setValues(initialState);
    });

    return () => {
      offCanvas.removeEventListener("shown.bs.offcanvas", () => {});
    };
  }, [accountId]);

  useEffect(() => {
    setInvalidPhone(
      !(isValidPhoneNumber("+91" + values.phone) || values.phone === "")
    );
  }, [values.phone]);


  useEffect(() => {
    console.log(values.profilePhoto);
  }, [values.profilePhoto]);

  const updateAccount = async (e) => {
    e.preventDefault();
    setValues((prev) => ({ ...prev, loading: true }));
    
    try {
      await api.updateAccount(
        accountId,
        values.name,
        values.phone,
        values.address,
        values.remark,
        values.profilePhoto
      );

      setValues(() => {
        const tempInitialState = { ...initialState };
        tempInitialState.status.message = "Account Updated Successfully!";
        tempInitialState.status.type = "success";
        return tempInitialState;
      });

      setTimeout(() => {
        hideOffCanvas("editAccountDrawer");
        updateRefresh("account");
        navigate("/khata/" + accountId);
      }, 500);
      setValues(initialState);

    } catch (error) {
      setValues((prev) => ({
        ...prev,
        loading: false,
        status: { type: "error", message: error.message },
      }));
    }
  };

  const handleProfilePhotoChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      updateField("profilePhoto", e.target.files[0]);
    }
  };

  return (
    <div className="offcanvas offcanvas-end text-danger"
      tabIndex="-1"
      id="editAccountDrawer">
      <div className="offcanvas-header bg-app text-light">
        <h3 className="offcanvas-title">Edit Account</h3>
        <button
          type="button"
          className="btn-close text-reset bg-light"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
      </div>
      <div className="offcanvas-body container-fluid">
        <form onSubmit={updateAccount} id="editaccountform">
          <div className="mb-3">
            <label htmlFor="profilePhoto" className="form-label">
              Profile Photo
            </label>
            <input
              type="file"
              className="form-control"
              id="profilePhoto"
              accept="image/*"
              onChange={handleProfilePhotoChange}
            />
            {values.profilePhoto && (
              <div className="mt-2 position-relative">
                <div className="position-absolute" style={{top: "10px", left: "10px", zIndex: "10001"}}>
                  <div 
                    className="btn btn-sm btn-danger rounded-circle"
                    style={{ padding: '0.25rem', lineHeight: 1 }}
                    onClick={() => {
                      updateField("profilePhoto", null);
                      profileRef.current = null;
                    }}
                  >
                    <RiCloseCircleFill size={16} />
                  </div>
                </div>
                <img
                  src={values.profilePhoto._url ? values.profilePhoto._url : URL.createObjectURL(values.profilePhoto)}
                  alt="Profile Preview"
                  className=""
                  style={{zIndex: "1"}}
                  width="100"
                />
              </div>
            )}
          </div>
          <div className="mb-3">
            <input
              type="text"
              className={`form-control`}
              placeholder="Account Name"
              value={values.name}
              onChange={(e) => updateField("name", e.target.value)}
              required
            />
          </div>
          <div className="mb-3">
            <input
              type="tel"
              className={`form-control ${
                invalidPhone ? "is-invalid" : values.phone ? "is-valid" : ""
              }`}
              placeholder="Phone no. (Optional)"
              value={values.phone}
              onChange={(e) => updateField("phone", e.target.value)}
            />
          </div>
          <div className="mb-3">
            <input
              type="text"
              className={`form-control ${values.address ? "is-valid" : ""}`}
              placeholder="Address (Optional)"
              value={values.address}
              onChange={(e) => updateField("address", e.target.value)}
            />
          </div>
          <div className="mb-3">
            <textarea
              type="text"
              className={`form-control ${values.markup ? "is-valid" : ""}`}
              placeholder="Remarks (Optional)"
              value={values.remark}
              onChange={(e) => updateField("remark", e.target.value)}
            />
          </div>
          <Loading enabled={values.loading} />
          <Status type={values.status.type} message={values.status.message} />
          <div className="d-grid">
            <button
              type="submit"
              className="btn btn-outline-danger py-3 fs-5 fw-bold"
              disabled={!values.name || invalidPhone}>
              UPDATE
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditAccount;
