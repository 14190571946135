import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Routes, Route, Link, Navigate, useLocation } from 'react-router-dom'
import { 
    Header, Menu, Footer 
} from 'components';

import { Login, Profile } from 'views/Auth';
import Cashbook from 'views/Cashbook'
import Passbook from 'views/Passbook'
import Khatabook from 'views/Khatabook'
import Settings from 'views/Settings'
import NoMatch from 'views/NoMatch.js'
import Khata from 'views/Khata'
import Public from 'views/Khata/Public'
import NoKhata from 'views/NoKhata'
import Dummy from 'views/Dummy'

import Committee from 'views/Committee'
import Club from 'views/Committee/Club'

import Help from 'views/Help';

import { auth, api } from 'services';

import {
    NewAccount,
    NewTxn,
    EditTxn,
    EditAccount,
    EditRTxn
} from 'forms';

import OnboardTour from 'views/OnboardTour'
import Onboarding from 'views/Onboarding'
import OnboardingTasksModal from 'components/onboarding-tasks'
import { Toaster } from 'sonner'
import useUniversalStore from 'store/universalStore';
import useUserStore from 'store/useUserStore';

const user = auth.fetchUser()

function App() {
    const location = useLocation();
    const fetchInitialData = useUniversalStore((state) => state.fetchInitialData);
    const getUser = useUserStore((state) => state.getUser);
  
    const showFooterPaths = ['/settings', '/profile', '/help'];
    const shouldShowFooter = showFooterPaths.includes(location.pathname);
    // const {write} = useUniversalStore()
    // const refreshTranscation = useUniversalStore(state=>state.refresh.transactionRefresh)
    // useEffect(() => {
    //     api.getAllTransactions().then((res) => {
    //         write({ transactions: res });
    //     })
    //     console.log("sooo many");
    // }, [refreshTranscation])
    useEffect(() => {
        fetchInitialData();
        getUser();
    }, [fetchInitialData, getUser]);

    const Template = ({ children }) => {
        return (
            <div className="row m-0">
                <div className="col-md-4 col-12 px-0 border-end border-secondary">
                    <Khatabook />
                </div>
                <div className="col-md-8 col-12 p-0 m-0">{children}</div>
            </div>
        )
    }

    return (
        <div className="vh-100 vw-100 overflow-hidden">
            <Toaster />
            <PrivateRouteData>
                <NewTxn />
                <EditTxn />
                <EditRTxn />
                <NewAccount />
                <EditAccount />
                <Menu />
                <OnboardTour />
                <OnboardingTasksModal />
                <Onboarding />
            </PrivateRouteData>
            <Routes>
                <Route path="/dummy" element={<Dummy/>} />
                <Route path="/help" element={<Help />} />
                <Route path="/login" element={<Login type="login"/>} />
                <Route path="/funding" element={<Committee/>} />
                <Route path="/club">
                    <Route path=":id" element={<PrivateRoute><Club /></PrivateRoute> } />
                </Route>
                <Route path="/profile" element={<PrivateRoute><Profile />{' '}</PrivateRoute> } />
                <Route path="/newtxn" element={<PrivateRoute><NewTxn /></PrivateRoute>} />
                <Route path="/newaccount" element={<PrivateRoute><NewAccount /></PrivateRoute>} />
                <Route path="/khata">
                    <Route path=":id" element={<PrivateRoute><Template><Khata /></Template></PrivateRoute>} />
                    <Route path="*" element={
                            <PrivateRoute>
                                <Template>
                                    <NoKhata />
                                </Template>
                            </PrivateRoute>
                        }
                    />
                </Route>
                <Route path="/p">
                    <Route path=":id" element={<Public />} />
                </Route>
                <Route path="/" element={
                        <PrivateRoute>
                            <Template>
                                <NoKhata />
                            </Template>
                        </PrivateRoute>
                    }
                />
                <Route path="/passbook" element={<PrivateRoute><Passbook /></PrivateRoute>} />
                <Route path="/cashbook" element={<PrivateRoute><Cashbook /></PrivateRoute>} />
                <Route path="/khatabook" element={ <PrivateRoute><Khatabook /></PrivateRoute>} />
                <Route path="/settings" element={<PrivateRoute><Settings /></PrivateRoute>} />
                <Route path="*" element={<NoMatch />} />
            </Routes>
            {shouldShowFooter && <Footer />}
        </div>
    )
}

const PrivateRoute = ({ children }) => {
    if (!user) {
        return <Navigate to="/login" />
    }
    return children
}

const PrivateRouteData = ({ children }) => {
    if (user && !window.location.href.includes('/p/')) {
        return (
            <>
                <Header />
                {children}
            </>
        )
    } else return null
}

export default App
