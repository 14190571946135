import { useEffect, useState } from "react";
import { api } from "services";

function ReminderAlert(props) {
  const { setShowReminder, showReminder, id, account, currentUser } = props;

  const handleSendReminder = () => {
    const num = account.get("phone");
    const name = currentUser.get("name");
    const bal = -account.get("balance");
    const line1 = "Payment Reminder : ";
    const line2 = `Balance *₹${bal}* due to *${name}*`;
    const link = `Ledger : ${
      process.env.NODE_ENV == "development"
        ? "http://localhost:3000"
        : process.env.REACT_APP_URL
    }/p/${id}`;
    const line3 = `- BahiKhata`;
    window.open(
      `https://wa.me/${num}?text=${line1}%0a${line2}%0a${link}%0a%0a${line3}`,
      "_blank"
    );
  };
  const handleClose = () => {
    api.AddReminder(id, null, false).then((res) => {
      setShowReminder(false);
    });
  };

  return (
    <>
      <div className="row position-fixed bottom-0 start-50 my-5 py-5 px-4 w-auto">
        {showReminder && (
          <div className="alert alert-danger" role="alert">
            <div className="d-flex justify-content-between align-content-center">
              <div className="alert-heading me-5">
                Alert : Payment overdue !!
              </div>
              {/* <hr /> */}
              {/* <div className="d-flex justify-content-end"> */}
              <div>
                <div className="btn btn-secondary me-2" onClick={handleClose}>
                  Close
                </div>
                <div onClick={handleSendReminder} className="btn btn-app border-app">
                  Send Reminder
                </div>
              </div>
              {/* </div> */}
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default ReminderAlert;
